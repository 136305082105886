import axios from "axios";
import { BACKEND_URL } from "../constants/contsants";


class AuthService {

    setTokenLocalStorage(data: any) {
        localStorage.setItem("token", JSON.stringify(data.access));
    }

    async login(username: string, password: string): Promise<Object> {
        const response = await axios.post(`${BACKEND_URL}/api/token/`, { username, password });
        if (!response.data) {
            return response.data;
        }
        this.setTokenLocalStorage(response.data.access);
        return response.data;
    }

    logout() {
        localStorage.removeItem("token");
    }

    getCurrentUser() {
        const user = localStorage.getItem("token")!;
        return user;
    }
}

export default new AuthService();