import type { FC } from "react"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import ProjectProgress from "../../widgets/ProjectProgress"
import ProjectComments from "../../widgets/ProjectComments"
import { useParams } from "react-router-dom"
import axios from "axios"
import { BACKEND_URL } from "../../shared/constants/contsants"
import ProjectStages from "../../widgets/ProjectStages"
import "./ProjectPage.scss"

const ProjectPage: FC = () => {

    const {project_id} = useParams()
    const [project, setProject] = useState<any>()
    const [projectURL, setProjectURL] = useState<any>()
    const [projectStages, setProjectStages] = useState<any>([])
    const [reload, setReload] = useState<boolean>(false)
    const token: any = localStorage.token?.replace(/"/g, "")
    const navigate = useNavigate()

    useEffect(() => {
        axios.get(`${BACKEND_URL}/project/get_all_projects/`, {
            headers: {
                Authorization: `JWT ${token}`
            }
        })
            .then(res => {
                res.data.map((item: any) => {
                    if (item?.id === Number(project_id)) {
                        setProject(item)
                        setProjectURL(item?.projecturlsmodel_data)
                        setProjectStages(item?.stage_data)
                    } else return
                })
            })
            .catch(err => navigate("/404", {replace: true}))
    }, [reload])

    return (
        <div className="project">
            <ProjectProgress name={project?.name} projectStages={projectStages} projectURL={projectURL}/>
            <ProjectComments setState={setReload} comments={project?.ticket_data}/>
            <ProjectStages projectStages={projectStages}/>
        </div>
    )
}

export default ProjectPage