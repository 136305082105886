import type { FC } from "react"
import Error404 from "../../widgets/Error404"

const Error404Page: FC = () => {
  return (
    <>
        <Error404/>
    </>
  )
}

export default Error404Page