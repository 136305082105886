import type { FC } from "react"
import classNames from "classnames"
import { THeaderBurgerButton } from "./types"
import "./HeaderBurgerButton.scss"

const HeaderBurgerButton: FC<THeaderBurgerButton> = (props) => {
    const {
        className,
        isMenuOpened,
        toggleIsMenuOpened,
        ...rest
    } = props
    return (
        <button
            className={classNames(className, "burger-button visible-mobile", {
                "is-opened" : isMenuOpened
            })}
            type="button"
            onClick={() => toggleIsMenuOpened()}
            aria-label="open menu"
            {...rest}
        >
            <svg className="burger-button__icon" width="41" height="29" viewBox="0 0 41 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13 0H41L28 7H0L13 0Z" fill="#00EE66"/>
                <path d="M13 11H41L28 18H0L13 11Z" fill="#00EE66"/>
                <path d="M13 22H41L28 29H0L13 22Z" fill="#00EE66"/>
            </svg>
            <svg className="burger-button__is-opened-icon" width="41" height="29" viewBox="0 0 41 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13 22H41L28 29H0L13 22Z" fill="#00EE66"/>
                <path d="M13 22L27 11L14 18L6.22864e-06 29L13 22Z" fill="#052219"/>
                <path d="M28.0002 29L6.125e-05 7.00006L13.0001 6.37521e-05L41 22L28.0002 29Z" fill="url(#paint0_linear_1421_249)"/>
                <path d="M14 18L27 11L41 0L28 7L14 18Z" fill="#052219"/>
                <path d="M13 0H41L28 7H0L13 0Z" fill="#00EE66"/>
                <defs>
                    <linearGradient id="paint0_linear_1421_249" x1="35.2166" y1="24.296" x2="8.21655" y2="2.79599" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#3DFF90"/>
                        <stop offset="1" stopColor="#052219"/>
                    </linearGradient>
                </defs>
            </svg>
        </button>
    )
}

export default HeaderBurgerButton