import React, { createContext, ReactNode, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios, { AxiosInstance } from "axios";

import AuthService from "../../shared/services/AuthService";
import authHeader from "../../shared/services/AuthHeader";


const DefaultProps = {
    login: () => null,
    getCurrentUser: () => null,
    setTokenLocalStorage: (user: Object) => null,
    authAxios: axios,
    user: null,
    logout: () => null,
};

export interface AuthProps {
    login: (username: string, password: string) => any;
    logout: () => void;
    getCurrentUser: () => void;
    setTokenLocalStorage: (user: Object) => void;
    authAxios: AxiosInstance;
    user: Object | null;
}

export const AuthContext = createContext<AuthProps>(DefaultProps);

export const AuthContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const navigate = useNavigate();
    const [user, setUser] = useState(() => AuthService.getCurrentUser());

    async function login(username: string, password: string) {
        try {
            const response = await AuthService.login(username, password);
            setTokenLocalStorage(response);

        } catch (error) {
            console.error('Error in login:', error);
            throw error; // Перебрасываем ошибку для дальнейшей обработки в .catch()
        }
    }

    function getCurrentUser() {
        return user;
    }

    function setTokenLocalStorage(user: any) {
        AuthService.setTokenLocalStorage(user);
        setUser(user);
    }

    function logout() {
        AuthService.logout();
        setUser("");
        navigate("/");
    }

    // axios instance для выполнения запросов
    const authAxios = axios.create();

    // request interceptor для добавления токена
    authAxios.interceptors.request.use((config) => {
        config.headers = authHeader();
        return config;
    });

    authAxios.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            if (error.response.status === 401) {
                logout();
            }
            return Promise.reject(error);
        }
    );

    // axios.interceptors.response.use(
    //     (response) => {
    //       return response
    //     },
    //     (error) => {
    //       return new Promise((resolve) => {
    //         const originalRequest = error.config
    //         const refreshToken = localStorage.get('token')
    //         if (error.response && error.response.status === 401 && error.config && !error.config.__isRetryRequest && refreshToken) {
    //           originalRequest._retry = true
      
    //           const response = fetch(api.refreshToken, {
    //             method: 'POST',
    //             headers: {
    //               'Content-Type': 'application/json',
    //             "Authorization" : `JWT ${localStorage.get('token')}`
    //             },
    //             body: JSON.stringify({
    //               refresh: refreshToken,
    //             }),
    //           })
    //             .then((res) => res.json())
    //             .then((res) => {
    //               localStorage.set(res.access, 'token')
      
    //               return axios(originalRequest)
    //             })
    //           resolve(response)
    //         }
      
    //         return Promise.reject(error)
    //       })
    //     },
    //   )

    return (
        <AuthContext.Provider value={{ user, login, setTokenLocalStorage, getCurrentUser, logout, authAxios }}>
            {children}
        </AuthContext.Provider>
    );
};
