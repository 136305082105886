import type { FC } from "react"
import { TStage } from "./types"
import "./Stage.scss"
import classNames from "classnames"

const Stage: FC<TStage> = (props) => {
    const {
        name,
        className,
        status,
        ...rest
    } = props


    return (
        <button
            className={classNames(className, `stage link stage ${status === "Релиз" ? "stage--release" : ""} ${status === "В работе" ? "stage--proccess" : ""} ${status === "Принят" ? "stage--uncomplete" : ""}`)}
            type="button"
            {...rest}
        >
            <span className="stage__text text text--medium-size stage__text">{name}</span>
        </button>
    )
}

export default Stage