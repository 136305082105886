import type { FC } from "react"
import Input from "../../shared/ui/Input"
import Button from "../../shared/ui/Button"
import RequestRadios from "./ui/RequestRadios"
import RequestCheckBoxes from "./ui/RequestCheckBoxes"
import { useForm } from "react-hook-form"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import "./Request.scss"
import { useState } from "react"
import Popup from "../../shared/ui/Popup"
import RequestPopup from "./ui/RequestPopup"
import { BACKEND_URL } from "../../shared/constants/contsants"
import { RequestSubmit } from "./types"
import axios from "axios"
import Loading from "../../shared/ui/Loading"

const schema = yup.object({
    name: yup.string().required("Незаполненное поле"),
    contact: yup.string().required("Незаполненное поле"),
    description: yup.string().required("Незаполненное поле"),
    budget: yup.string().required("Незаполненное поле"),
    policy: yup.boolean().isTrue("Должен быть True")
})

const Request: FC = () => {
    const {
        register,
        reset,
        handleSubmit,
        formState: {
            errors
        }
    } = useForm({
        resolver: yupResolver(schema),
    })
    const [isActivePopup, setIsActivePopup] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState("")


    const onSubmit = ({name, contact, description, budget}: RequestSubmit) => {
        setIsLoading(true)
        axios.post(`${BACKEND_URL}/requests/submit_request/`, {name, contact, description, budget}, {
            headers: {
                "Content-type": "application/json"
            }
 
        })
            .then(() => {
                setIsActivePopup(true)
                reset()
            })
            .catch(err => setError(err?.message))
            .finally(() => {
                setIsLoading(false)
            })
    }

    return (
        <form className="request" onSubmit={handleSubmit(onSubmit)} id="request-form">
            <h2 className="request__title title">Leave a request now!</h2>
            <div className="request__body">
                <div className="request__inputs">
                    <Input
                        className="request__input"
                        placeholder="What is your name?"
                        register={register("name")}
                        hasError error={errors.name?.message}
                    />
                    <Input
                        className="request__input"
                        placeholder="Email, phone or messenger"
                        register={register("contact")}
                        hasError error={errors.contact?.message}
                    />
                    <Input
                        className="request__input"
                        placeholder="Briefly describe the task..."
                        register={register("description")}
                        hasError error={errors.description?.message}
                    />
                </div>
                <RequestRadios register={register}/>
                <RequestCheckBoxes register={register}/>
                <Button className="request__button" type="submit">Send</Button>
                {error && <div className="request__error error">{error}</div>}
            </div>
                <Popup className="popup--big" popupIsActive={isActivePopup} setState={setIsActivePopup}>
                    <RequestPopup/>
                </Popup>
            {isLoading && <Loading isFullScreen/>}
        </form>
    )
}

export default Request