import { useState, type FC, FormEvent } from "react"
import Button from "../../../../shared/ui/Button"
import axios from "axios"
import { BACKEND_URL } from "../../../../shared/constants/contsants"
import { useParams } from "react-router-dom"
import { TCommentForm } from "./types"
import Loading from "../../../../shared/ui/Loading"
import "./ProjectCommentsForm.scss"

const ProjectCommentsForm: FC<TCommentForm> = (props) => {
    const {
        setState
    } = props

    const [commentText, setCommentText] = useState<string>("")
    const [isLoading, setIsLoading] = useState(false)
    const token: any = localStorage.token?.replace(/"/g, "")
    const {project_id} = useParams()

    function createTicket() {
        setIsLoading(true)
        if (commentText !== "") {
            axios.post(`${BACKEND_URL}/ticket/create_ticket/?project=${project_id}`, {
                title: "test",
                description: commentText
            }, {
                headers: {
                    Authorization: `JWT ${token}`,
                    "Content-Type": "application/json"
                }
            })
                .then(() => {
                    setCommentText("")
                    setState((a: any) => !a)
                })
                .catch(err => console.log(err))
                .finally(() => setIsLoading(false))
        } else return
    }

    function handleSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault()
        createTicket()
    }

    return (
        <form className="project__form" onSubmit={handleSubmit}>
            <textarea
                className="project__form-textarea link link--small link--regular"
                placeholder="Leave a comment or make an edit..." value={commentText}
                onChange={(event) => setCommentText(event.target.value)}
            />
            <div className="project__form-buttons">
                <Button
                    type="button"
                    className="button--no-background button--form project__form-button--left text text--green button--big-after"
                >
                    +
                </Button>
                <Button
                    type="submit"
                    className="button--no-background button--form project__form-button--right text button--big-after"
                >
                    Send
                </Button>
            </div>
            {isLoading && <Loading isFullScreen={true}/>}
        </form>
    )
}

export default ProjectCommentsForm