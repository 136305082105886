import type { FC } from "react"
import type { TStagesDevelopmentBox } from "./types"
import "./StagesDevelopmentBox.scss"

const StagesDevelopmentBox: FC<TStagesDevelopmentBox> = (props) => {
    const {
        stage,
        name
    } = props

    return (
        <article className="stages-development-box">
            <div className="stages-development-box__body">
                <div className="stages-development-box__number title title--very-small">{stage}</div>
                <div className="stages-development-box__text title title--very-small">
                    {name}
                </div>
            </div>
        </article>
    )
}

export default StagesDevelopmentBox