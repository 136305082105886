import type { FC } from "react"
import "./ProtectedRoute.scss"
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute: FC = () => {
  return (
    localStorage.token ? <Outlet /> : <Navigate to="/" replace/>
  )
}

export default ProtectedRoute