import type { FC } from "react"
import type { THeaderMobileMenu } from "./types"
import classNames from "classnames"
import { Link } from "react-router-dom"
import "./HeaderMobileMenu.scss"

const HeaderMobileMenu: FC<THeaderMobileMenu> = (props) => {
    const {
        className,
        isMenuOpened,
        setIsMenuOpened,
        ...rest
    } = props

    return (
        <div
            className={classNames(className, "mobile-menu visible-mobile", {
                "is-opened": isMenuOpened
            })}
            {...rest}
        >
            <ul className="mobile-menu__list">
                <li className="mobile-menu__item">
                    <Link className="mobile-menu__link link" to="/#portfolio" onClick={() => setIsMenuOpened(false)}>Our work</Link>
                </li>
                <li className="mobile-menu__item">
                    <Link className="mobile-menu__link link" to="/#services" onClick={() => setIsMenuOpened(false)}>Services</Link>
                </li>
                <li className="mobile-menu__item">
                    <Link className="mobile-menu__link link" to="/#contact" onClick={() => setIsMenuOpened(false)}>Contact</Link>
                </li>
            </ul>
        </div>
    )
}

export default HeaderMobileMenu