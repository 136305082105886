import type { FC } from "react"
import { useState } from "react"
import { TCheckBox } from "./types"
import "./CheckBox.scss"

const CheckBox: FC<TCheckBox> = (props) => {
    const {
        name,
        label,
        isCheckedDefault = false,
        register,
    } = props
    const [isChecked, setIsChecked] = useState(isCheckedDefault)

    return (
        <div className="checkbox">
                <input
                    className="checkbox__control"
                    {...register}
                    id={ name }
                    checked={ isChecked }
                    onChange={ () => setIsChecked(!isChecked) }
                    type="checkbox"
                />
                <label className="checkbox__label" htmlFor={ name }>{ label }</label>
        </div>
    )
}

export default CheckBox