import type { FC } from "react"
import "./RequestPopup.scss"

const RequestPopup: FC = () => {
    return (
        <div className="request__popup">
            <h3 className="request__popup-title title">
                Thank you for your request!
            </h3>
            <div className="request__popup-text text">
                <p>
                    Our manager will contact you at the specified contact in the near future, if you have any questions,
                    write
                    to us in <a className="request__popup-link" href="https://t.me/rulebreakers_team">Telegram</a>
                </p>
            </div>
        </div>
    )
}

export default RequestPopup