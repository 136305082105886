import type { FC } from "react"
import { TButton } from "./types"
import "./Button.scss"
import classNames from "classnames"

const Button: FC<TButton> = (props) => {
    const {
        className,
        type = "button",
        children,
        ...rest
    } = props

    return (
        <button
            className={classNames(className, "button")}
            type={type}
            {...rest}
        >
            <div className="button__up"></div>
            <div className="button__down"></div>
            {children}
        </button>
    )
}

export default Button