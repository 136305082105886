import { useState, type FC, useRef } from "react"
import { useContext } from "react"
import { AuthContext } from "../../../../app/providers/AuthContextProvider"
import { useNavigate } from "react-router-dom"
import Button from "../../../../shared/ui/Button"
import Popup from "../../../../shared/ui/Popup"
import Login from "../../../../features/Login"
import { Link } from "react-router-dom"
import HeaderBurgerButton from "./ui/HeaderBurgerButton"
import useToggle from "../../../../shared/hooks/useToggle"
import HeaderMobileMenu from "./ui/HeaderMobileMenu"
import useClickOutside from "../../../../shared/hooks/useClickOutside"
import "./Header.scss"

const Header: FC = () => {
    const [popupIsActive, setPopupIsActive] = useState<boolean>(false)
    const {user, logout} = useContext(AuthContext)
    const navigate = useNavigate()

    const {
        value: isMenuOpened,
        toggleState: toggleIsMenuOpened,
        setValue: setIsMenuOpened,
    } = useToggle(false)

    const ref = useRef(null)
    useClickOutside(() => setIsMenuOpened(false), ref)

    function openPopupLogin() {
        setPopupIsActive(true)
        document.addEventListener("keydown", handleEscClose)
    }

    function closePopups() {
        setPopupIsActive(false)
    }

    function handleEscClose(evt: any) {
        if (evt) {
            if (evt.key === "Escape") {
                closePopups()
            }
        }
    }

    return (
        <header className="header">
            <nav className="header__body container">
                <div className="header__mobile-container visible-mobile" ref={ref}>
                    <HeaderBurgerButton
                        className="header__burger-button"
                        isMenuOpened={isMenuOpened}
                        toggleIsMenuOpened={toggleIsMenuOpened}
                    />
                    <HeaderMobileMenu
                        className="header__mobile-menu"
                        isMenuOpened={isMenuOpened}
                        setIsMenuOpened={setIsMenuOpened}
                    />
                </div>
                <ul className="header__list hidden-mobile">
                    <li className="header__item">
                        <Link className="header__link link" to="/#portfolio">Our work</Link>
                    </li>
                    <li className="header__item">
                        <Link className="header__link link" to="/#services">Services</Link>
                    </li>
                    <li className="header__item">
                        <Link className="header__link link" to="/#contact">Contact</Link>
                    </li>
                </ul>
                <div className="header__actions">
                    {user ?
                        <div className="header__logout">
                            <img className="header__logout-image" src={"/images/content/User.png"} alt=""
                                 onClick={() => {
                                     navigate("/profile", {replace: true})
                                 }}/>
                            <Button className="button--login text text--small" onClick={logout}>Log out</Button>
                        </div>
                        :
                        <Button className="button--login text text--small" onClick={openPopupLogin}>Log in</Button>
                    }
                </div>
            </nav>
            <Popup popupIsActive={popupIsActive} setState={setPopupIsActive}><Login
                setState={setPopupIsActive}/></Popup>
        </header>
    )
}

export default Header