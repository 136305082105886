import type { FC } from "react"
import type { TServicesRow } from "./types"
import "./ServicesRow.scss"
import classNames from "classnames"

const ServicesRow: FC<TServicesRow> = (props) => {
    const {
        text,
        image,
        isImageBig,
        isImageArrow,
        title
    } = props

    return (
        <article className="services-row">
            <div className="services-row__up"/>
            <div className="services-row__down"/>
            <div className="services-row__body">
                <h3 className="services-row__title title title--very-small">{title}</h3>
                <img
                    className={classNames("services-row__image", {
                        "is-big": isImageBig,
                        "is-arrow": isImageArrow
                    })}
                    src={image}
                    alt=""
                    width="189" height="192" loading="lazy"
                />
                <div className="services-row__text text">
                    <p>
                        {text}
                    </p>
                </div>
            </div>
        </article>
    )
}

export default ServicesRow