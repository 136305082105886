import type { FC, PropsWithChildren } from "react"
import "./Content.scss"

const Content: FC<PropsWithChildren> = (props) => {
    const {children} = props

    return (
        <main className="content">
            {children}
        </main>
    )
}

export default Content