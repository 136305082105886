import type { FC } from "react"
import Preview from "../../widgets/Preview"
import About from "../../widgets/About"
import MonitorProgress from "../../widgets/MonitorProgress"
import Portfolio from "../../widgets/Portfolio"
import Services from "../../widgets/Services"
import StagesDevelopment from "../../widgets/StagesDevelopment"
import Contact from "../../widgets/Contact"
import LeaveRequest from "../../widgets/LeaveRequest"


const MainPage: FC = () => {
    return (
        <>
            <Preview/>
            <About/>
            <MonitorProgress/>
            <Portfolio/>
            <Services/>
            <StagesDevelopment/>
            <LeaveRequest/>
            <Contact/>
        </>
    )
}

export default MainPage