import { type FC } from "react"
import { Link } from "react-router-dom"
import ProjectCommentsForm from "./ui/ProjectCommentsForm"
import ProjectCommetsComment from "./ui/ProjectCommetsComment"
import { TComments } from "./types"
import { TTicket } from "../../shared/types/comon"
import Slider from "../../shared/ui/Slider"
import "./ProjectComments.scss"

const ProjectComments: FC<TComments> = (props) => {
    const {
        comments,
        setState
    } = props

    const commentsSlice = comments?.slice(-4).reverse()

    return (
        <section className="project__comments">
            <div className="project__comments-header container">
                <div className="project__comments-title">
                    <h2 className="title title--very-small">Your comments on the project</h2>
                    <Link
                        to="comments"
                        relative="path"
                        className="project__comments-title--link link link--small link--medium"
                    >
                        See all
                    </Link>
                </div>
            </div>
            <div className="project__comments-container container">
                <Slider>
                    <ul className="project__comments-list">
                        <li className="project__comments-item">
                            <ProjectCommentsForm setState={setState}/>
                        </li>
                        {commentsSlice?.map((comment: TTicket) => {
                            return (
                                <li className="project__comments-item" key={comment?.id}>
                                    <ProjectCommetsComment
                                        id={comment?.id}
                                        title={comment?.title}
                                        description={comment?.description}
                                        status={comment?.status}
                                        date={comment.created_date}
                                    />
                                </li>
                            )
                        })}
                    </ul>
                </Slider>
            </div>
        </section>
    )
}

export default ProjectComments