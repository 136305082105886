import type { FC } from "react"
import "./About.scss"

const About: FC = () => {
    return (
        <section className="about" id="about">
            <div className="about__inner container">
                <div className="about__body">
                    <h2 className="about__title title">About_</h2>
                    <div className="about__text text">
                        <p>
                            RuleBreakers is a premium IT studio offering innovative and exclusive technological
                            solutions for the most demanding customers.
                        </p>
                        <p>
                            We combine creativity, technological literacy and foresight of the future to overcome the
                            standard framework and create products that rewrite the rules of the game.
                        </p>
                        <p>
                            We believe that rules exist in order to break them. Our mission is not just to follow
                            trends, but to set them by developing solutions that will change the industry and set new
                            quality standards and innovations.
                        </p>
                    </div>
                    <div className="about__video-wrapper">
                        <video className="about__video" autoPlay loop={true} muted>
                            <source src="/videos/gridsphere_BG.mp4" type="video/mp4"/>
                        </video>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About