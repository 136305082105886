import type { FC } from "react"
import type { TProgressStrip } from "./types"
import ProgressStripColumn from "./ui/ProgressStripColumn"
import Slider from "../../../../shared/ui/Slider"
import "./ProgressStrip.scss"

const ProgressStrip: FC<TProgressStrip> = (props) => {
    const {
        projectStages
    } = props

    return (
        <div className="progress-strip">
            <div className="progress-strip__body">
                <Slider>
                    <ul className="progress-strip__stage-list">
                        {projectStages.map(({id, status, name}) => (
                            <li className="progress-strip__stage-item" key={id}>
                                <ProgressStripColumn name={name} status={status}/>
                            </li>
                        ))}
                    </ul>
                </Slider>
            </div>
        </div>
    )
}

export default ProgressStrip