import type { FC } from "react"
import StagesDevelopmentBox from "./ui/StagesDevelopmentBox"
import { Swiper, SwiperSlide } from "swiper/react"
import "./StagesDevelopment.scss"
import "swiper/css"
import Slider from "../../shared/ui/Slider"

const StagesDevelopment: FC = () => {
    const slideArray = [
        {
            stage: "01",
            name: "Project registration/review"
        },
        {
            stage: "02",
            name: "Project approval"
        },
        {
            stage: "03",
            name: "Getting all the information from the client"
        },
        {
            stage: "04",
            name: "Checking information"
        },
        {
            stage: "05",
            name: "Drawing up a time shield"
        },
        {
            stage: "06",
            name: "Launching the project"
        },
    ]

    return (
        <section className="stages-development">
            <div className="stages-development__inner">
                <h2 className="stages-development__title title">Stages of digital product development_</h2>
                <div className="stages-development__body">
                    <Slider>
                        <ul className="stages-development__stage-list">
                            {slideArray.map(({stage, name}) => (
                                <li className="stages-development__stage-item" key={stage}>
                                    <StagesDevelopmentBox stage={stage} name={name}/>
                                </li>
                            ))}
                        </ul>
                    </Slider>
                 </div>
            </div>
        </section>
    )
}

export default StagesDevelopment