import type { FC, PropsWithChildren } from "react"
import { useEffect, useRef } from "react"
import "./Slider.scss"

const Slider: FC<PropsWithChildren> = (props) => {
    const {
        children
    } = props

    const ref = useRef<HTMLDivElement>(null)
    let dragStart = false
    let cursorStartPosition: number
    let divStartPosition: number
    let clickOnElement = false

    const mouseDown = (event: MouseEvent) => {
        if (!ref.current) return

        dragStart = true
        cursorStartPosition = event.clientX
        divStartPosition = ref.current.scrollLeft
        const path = event.composedPath()
        const isClickOutside = path.includes(ref.current)
        if (isClickOutside) {
            clickOnElement = true
        }
    }

    const mouseUp = (event: MouseEvent) => {
        if (!ref.current) return

        dragStart = false
        clickOnElement = false
        ref.current.classList.remove("is-dragged")
    }

    const mouseMove = (event: MouseEvent) => {
        if (!ref.current) return

        if (dragStart && clickOnElement) {
            ref.current.classList.add("is-dragged")
            ref.current.scrollLeft = divStartPosition + (cursorStartPosition - event.clientX)
        }
    }

    useEffect(() => {
        document.addEventListener("mousedown", mouseDown)
        document.addEventListener("mouseup", mouseUp)
        document.addEventListener("mousemove", mouseMove)


        return () => {
            document.removeEventListener("mousedown", mouseDown)
            document.removeEventListener("mouseup", mouseUp)
            document.removeEventListener("mousemove", mouseMove)
        }
    }, [])

    return (
        <div className="slider">
            <div className="slider__body" ref={ref}>
                {children}
            </div>
        </div>
    )
}

export default Slider