import { AxiosRequestHeaders } from "axios";

export default function authHeader(): AxiosRequestHeaders {
    const localstorageUser = localStorage.getItem("token");
    if (!localstorageUser) {
        return {} as AxiosRequestHeaders;
    }
    const user = JSON.parse(localstorageUser);
    if (user) {
        return { Authorization: `JWT ${user.replace(/"/g, '')}` } as AxiosRequestHeaders;
    }
    return {} as AxiosRequestHeaders;
}