import type { FC } from "react"
import Header from "./ui/Header"
import Content from "./ui/Content"
import { Outlet } from "react-router-dom"
import Footer from "./ui/Footer"
import "./Layout.scss"

const Layout: FC = () => {
    return (
        <>
            <Header/>
            <Content>
                <Outlet/>
            </Content>
            <Footer/>
        </>
    )
}

export default Layout