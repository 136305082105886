import type { FC } from "react"
import Request from "../../features/Request"
import "./LeaveRequest.scss"

const LeaveRequest: FC = () => {
    return (
        <section className="leave-request">
            <div className="leave-request__inner container">
                <div className="leave-request__up" />
                <div className="leave-request__down" />
                <div className="leave-request__body">
                    <Request/>
                </div>
            </div>
        </section>
    )
}

export default LeaveRequest