import type { FC } from "react"
import Button from "../../../../shared/ui/Button"
import { useState } from "react"
import classNames from "classnames"
import Task from "./ui/Task"
import { TProjectStagesTask } from "./types"
import "./ProjectStagesTask.scss"

const ProjectStagesTask: FC<TProjectStagesTask> = (props) => {
    const {
        tasks
    } = props
    const [filter, setFilter] = useState("Все")

    const buttonsFilter = [
        {
            filterButton: "Все",
            text: "Show all"
        },
        {
            filterButton: "Релиз",
            text: "Done"
        },
        {
            filterButton: "В работе",
            text: "In progress"
        },
    ]

    const filteredArray = tasks?.filter(({status}) => {
        if (filter === "Все") return true
        return status === filter
    })
    const hasFilteredArray = filteredArray?.length > 0

    return (
        <div className="project-stages-task container">
            <h3 className="project-stages-task__title text text--rock-star">Tasks in the stage</h3>
            <div className="project-stages-task__actions">
                {buttonsFilter.map(({filterButton, text}) => (
                    <Button
                        className={classNames("project-stages-task__button button--simply", {
                            "is-active": filter === filterButton,
                        })}
                        key={filterButton}
                        onClick={() => setFilter(filterButton)}
                    >
                        {text}
                    </Button>
                ))}
            </div>
            {hasFilteredArray ? (
                <ul className="project-stages-task__task-list">
                    {filteredArray?.map(({status, task, id}) => (
                        <li className="project-stages-task__task-item" key={id}>
                            <Task text={task} status={status}/>
                        </li>
                    ))}
                </ul>
            ) : (
                <div className="project-stages-task__title title title--very-small">No tasks:(</div>
            )
            }

        </div>
    )
}

export default ProjectStagesTask