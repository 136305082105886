import { type FC } from "react"
import "./UserProject.scss"
import ProgressStrip from "../../shared/ui/ProgressStrip"
import { Link } from "react-router-dom"
import { TProject } from "./types"

const UserProject: FC<TProject> = (props) => {
  
  const {id, name, description, stage_data, ticket_data} = props
  const getReadyProgres = () => {
    let readyStages = 0
    stage_data?.forEach(stage => {
        if (stage.status === "Релиз") {
            readyStages += 1;
        }
    })
    if (readyStages !== 0) {
      let readyStage = Math.floor((readyStages / stage_data?.length) * 100);
      return readyStage
    } else {
      return 0
    }
  }
  
  const progress = (getReadyProgres())



  return (
    <Link to={`/profile/${id}`} className="profile__project">
      <div className="profile__project-info">
        <h3 className="title title--very-small">{name}</h3>
        <p className="subtitle">{description}</p>
      </div>
      <div className="profile__project-progress">
        <div className="profile__project-progress-text">
          <span className="profile__project-progress-text--top">Project progress</span>
          <span className="link link--small">Ready for {progress} %</span>
        </div>
        <div className="profile__project-progress-strip">
          <ProgressStrip progress={progress}/>
        </div>
      </div>
    </Link>
  )
}

export default UserProject