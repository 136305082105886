import { FC, useEffect } from "react"
import { TPopup } from "./types"
import classNames from "classnames"
import useBodyLock from "../../hooks/useBodyLock"
import "./Popup.scss"

const Popup: FC<TPopup> = (props) => {
    const {
        children,
        setState,
        className,
        popupIsActive
    } = props

    const bodyLock = useBodyLock()
    useEffect(() => {
        if (popupIsActive) {
            bodyLock(true)
        } else {
            bodyLock(false)
        }

    }, [popupIsActive])

    return (
        <div className={classNames(
            className,
            "popup",
            {"popup--active" : popupIsActive}
            )}>
            <div className="popup__overlay" onClick={() => setState(false)}></div>
            <div className="popup__container">
                <button className="popup__close-button" onClick={() => setState(false)}>
                    <svg className="popup__close-icon" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.1428 1.14284L5.00006 4.99994M8.85707 8.85714L5.00006 4.99994M8.85717 1.14276L5.00006 4.99994M1.14279 8.85714L5.00006 4.99994" stroke="#00EE66" stroke-width="1.5" stroke-linecap="round"/>
                    </svg>
                </button>
                {children}
            </div>
        </div>
    )
}

export default Popup