import type { FC } from "react"
import "./ProjectCommetsComment.scss"
import { TComment } from "../../../../pages/ProjectPage/types"
import getDateFormatted from "../../../../shared/utils/getDateFormatted"

const ProjectCommetsComment: FC<TComment> = (props) => {
    const {
        description,
        status,
        date
    } = props

    const formattedDate = getDateFormatted(date)
    return (
        <div
            className={`project__comment ${status === "Релиз" ? "project__comment--release" : ""} ${status === "В работе" ? "project__comment--in-progress" : ""} ${status === "Принят" ? "project__comment--accept" : ""}`}>
            <span className="project__comment-text link link--regular link--black">{description}</span>
            <div className="project__comment-bottom">
                <span className="project__comment-date text text--very-small text--gray">{formattedDate}</span>
                <span
                    className="project__comment-status text text--black">{status === "Релиз" ? "✓" : ""} {status === "В работе" ? "?" : ""}</span>
            </div>
        </div>
    )
}

export default ProjectCommetsComment