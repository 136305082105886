import type { FC } from "react"
import Logo from "../../../../shared/ui/Logo"
import { Link } from "react-router-dom"
import "./Footer.scss"

const Footer: FC = () => {
    return (
        <footer className="footer">
            <div className="footer__body container">
                <Logo className="footer__logo"/>
                <ul className="footer__list">
                    <li className="footer__item">
                        <Link to="/#about" className="footer__link link">About</Link>
                    </li>
                    <li className="footer__item">
                        <Link to="/#portfolio" className="footer__link link">Our work</Link>
                    </li>
                    <li className="footer__item">
                        <Link to="/#services" className="footer__link link">Services</Link>
                    </li>
                </ul>
                <div className="footer__contact">
                    <a href="mailto:nikita.s.mozhaev@rulebreakers.tech" className="footer__contact-link"
                       aria-label="to our email" title="to email">
                        <svg width="26" height="21" viewBox="0 0 26 21" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M2.33333 2.35714L10.4768 8.61665L10.4796 8.61894C11.3838 9.29389 11.8362 9.63156 12.3317 9.76201C12.7696 9.87731 13.23 9.87731 13.668 9.76201C14.1639 9.63144 14.6176 9.29278 15.5234 8.61665C15.5234 8.61665 20.7468 4.53663 23.6667 2.35714M1 15.6574V5.34312C1 3.82298 1 3.06234 1.29065 2.48172C1.54631 1.971 1.95396 1.55607 2.45573 1.29584C3.02616 1 3.77345 1 5.26693 1H20.7336C22.2271 1 22.9728 1 23.5432 1.29584C24.045 1.55607 24.454 1.971 24.7096 2.48172C25 3.06177 25 3.82149 25 5.33866V15.662C25 17.1792 25 17.9378 24.7096 18.5178C24.454 19.0286 24.045 19.4442 23.5432 19.7044C22.9733 20 22.228 20 20.7375 20H5.26255C3.77199 20 3.0256 20 2.45573 19.7044C1.95396 19.4442 1.54631 19.0286 1.29065 18.5178C1 17.9372 1 17.1776 1 15.6574Z"
                                stroke="#F0EFF2" strokeWidth="2" strokeLinecap="round"
                                strokeLinejoin="round"/>
                        </svg>
                    </a>
                    <a className="footer__contact-link" href="https://t.me/rules_support"
                       target="_blank" aria-label="to our telegram" title="to telegram">
                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M10.8629 14.1369L17.1288 7.87089M23.5386 4.04686L18.2495 21.2366C17.7755 22.7771 17.5383 23.5477 17.1295 23.8032C16.7749 24.0247 16.3359 24.0618 15.9497 23.9015C15.5043 23.7167 15.1429 22.9951 14.4219 21.553L11.0719 14.8529C10.9574 14.6241 10.9002 14.5101 10.8237 14.411C10.7559 14.323 10.6776 14.2437 10.5897 14.1759C10.4928 14.1012 10.3807 14.0452 10.1621 13.9359L3.44661 10.5781C2.00455 9.85704 1.28346 9.49619 1.09868 9.05087C0.938429 8.66466 0.974974 8.22521 1.19653 7.87061C1.45201 7.46172 2.22256 7.22418 3.76354 6.75003L20.9531 1.46088C22.1645 1.08812 22.7705 0.901886 23.1797 1.05211C23.5361 1.18296 23.8171 1.46368 23.9479 1.82011C24.0981 2.22912 23.9117 2.8348 23.5393 4.04507L23.5386 4.04686Z"
                                stroke="#F0EFF2" strokeWidth="2" strokeLinecap="round"
                                strokeLinejoin="round"/>
                        </svg>
                    </a>
                </div>
            </div>
        </footer>
    )
}

export default Footer