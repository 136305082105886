import type { FC } from "react"
import { TProfileProject } from "./types"
import "./PortfolioProject.scss"

const PortfolioProject: FC<TProfileProject> = (props) => {
    const {
        title,
        img,
        hash
    } = props

    return (
        <article className="portfolio-project">
            <div className="portfolio-project__info">
                <h3 className="portfolio-project__title title title--very-small">
                    {title}
                </h3>
                <ul className="portfolio-project__tag-list">
                    {hash.map((element) => (
                        <li className="portfolio-project__tag-item" key={element}>
                            {element}
                        </li>
                    ))}
                </ul>
            </div>
            <div className="portfolio-project__image-wrapper">
                <img
                  className="portfolio-project__image"
                  src={img}
                  alt="Проект"
                  width="375" height="422" loading="lazy"
                />
            </div>
        </article>
    )
}

export default PortfolioProject