import { type FC } from "react"
import ProjectLink from "../ProjectLink"
import ProgressStrip from "./ui/ProgressStrip"
import { TProjectURL } from "./types"
import { Link } from "react-router-dom"
import "./ProjectProgress.scss"

const ProjectProgress: FC<TProjectURL> = (props) => {
    const {
        projectURL,
        name,
        projectStages
    } = props
    
    const figma = projectURL?.find((element) => element.name === "figma")
    const notion = projectURL?.find((element) => element.name === "notion")
    const googleDrive = projectURL?.find((element) => element.name === "google")
    const getReadyProgres = () => {
        let readyStages = 0
        projectStages?.forEach(stage => {
            if (stage.status === "Релиз") {
                readyStages += 1;
            }
        })
        if (readyStages !== 0) {
          let readyStage = Math.floor((readyStages / projectStages?.length) * 100);
          return readyStage
        } else {
          return 0
        }
    }

    const progress = (getReadyProgres())

    return (
        <section className="project__progress">
            <div className="project__progress-inner container">
                <Link to="/profile" className="project__link link link--small link--regular">
                    ← Back
                </Link>
                <h2 className="project__progress-title title">{name}</h2>
                <span className="link link--small">Ready for {progress} %</span>
                <ProgressStrip projectStages={projectStages}/>
                <div className="project__links-grid">
                    <ProjectLink img={"/images/content/Figma.png"} text={"View design"} link={figma?.url}/>
                    <ProjectLink img={"/images/content/Notion.png"} text={"View the report"} link={notion?.url}/>
                    <ProjectLink img={"/images/content/GoogleDrive.png"} text={"Watch the presentation"} link={googleDrive?.url}/>
                </div>
            </div>
        </section>
    )
}

export default ProjectProgress