import type { FC } from "react"
import type { TLink } from "./types"
import "./ProjectLink.scss"

const ProjectLink: FC<TLink> = (props) => {

    const {link, text, img} = props

    return (
        <a href={link} target="_blank" rel="noreferrer" className="link-form">
            <img className="link-form__image" src={img} alt=""/>
            <span className="link--regular">{text}</span>
        </a>
    )
}

export default ProjectLink