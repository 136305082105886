import React from "react"
import { withProviders } from "./providers"
import { Route, Routes } from "react-router-dom"
import MainPage from "../pages/MainPage"
import ProfilePage from "../pages/ProfilePage"
import ProjectPage from "../pages/ProjectPage"
import Layout from "../widgets/Layout"
import CommentsPage from "../pages/CommentsPage"
import Error404Page from "../pages/Error404Page"
import ScrollToHashElement from "../widgets/ScrollToHashElement"
import ProtectedRoute from "../features/ProtectedRoute"
import { AuthContextProvider } from "./providers/AuthContextProvider"
import "./styles"

const App = () => {
    return (
        <div className="app">
            <ScrollToHashElement/>
            <AuthContextProvider>
                <Routes>
                    <Route path="/" element={<Layout/>}>
                        <Route index element={<MainPage/>}/>
                        <Route element={<ProtectedRoute/>}>
                            <Route path="profile" element={<ProfilePage/>}/>
                            <Route path="profile/:project_id" element={<ProjectPage/>}/>
                            <Route path="profile/:project_id/comments" element={<CommentsPage/>}/>
                        </Route>
                        <Route path="*" element={<Error404Page/>}/>
                    </Route>
                </Routes>
            </AuthContextProvider>
        </div>
    )
}

export default withProviders(App)
