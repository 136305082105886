import { type FC, useState } from "react"
import Input from "../../shared/ui/Input"
import Button from "../../shared/ui/Button"
import { TLogin, TLoginForm } from "./types"
import { useContext } from "react"
import { AuthContext } from "../../app/providers/AuthContextProvider"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import "./Login.scss"
import Loading from "../../shared/ui/Loading"

const schema = yup.object({
    login: yup.string().required("Незаполненное поле"),
    password: yup.string().required("Незаполненное поле"),
})

const Login: FC<TLogin> = (props) => {
    const {
        setState
    } = props

    const {login} = useContext(AuthContext)
    const {
        register,
        handleSubmit,
        formState: {
            errors
        }
    } = useForm({
        resolver: yupResolver(schema),
    })
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState("")
    const onSubmit = (data: TLoginForm) => {
        setIsLoading(true)
        login(data.login, data.password)
            .then(() => setState(false))
            .catch((error: any) => setError(error?.message))
            .finally(() => setIsLoading(false))

     }
 

    return (
        <form className="login" onSubmit={handleSubmit(onSubmit)}>
            <h2 className="title--small">Log in</h2>
            <div className="login__inputs-container">
                <Input
                    type="text"
                    placeholder="Login"
                    register={register("login")}
                    hasError error={errors.login?.message}
                />
                <Input
                    type="password"
                    register={register("password")}
                    hasError error={errors.password?.message}
                    placeholder="Password"
                />
            </div>
            <span className="login__info text text--white text--small">For a logit and password, you need to contact the manager, if you have lost the data for authorization, let us know in <a
                className="request__popup-link" href="https://t.me/rulebreakers_team">Telegram</a></span>
                <Button className="login__button button button__popup text text--small" type="submit">Log in</Button>
                {error && <div className="login__error error">{error}</div>}
            {isLoading && <Loading isFullScreen/>}
        </form>
    )
}

export default Login