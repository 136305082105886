import type { FC } from "react"
import CheckBoxesGroup from "../../../../shared/ui/CheckBoxesGroup"
import { TRequestCheckBoxes } from "./types"
import "./RequestCheckBoxes.scss"

const RequestCheckBoxes: FC<TRequestCheckBoxes> = (props) => {
    const {
        register,
    } = props

    const checkboxes = [
        {
            name: "policy",
            label: "By clicking «Send» you agree to the privacy policy and consent to the processing of personal data",
            hasError: true,
        }
    ]
    return (
        <div className="request__checkboxes">
            <CheckBoxesGroup register={register} checkboxes={checkboxes}/>
        </div>
    )
}

export default RequestCheckBoxes