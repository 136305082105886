import type { FC } from "react"
import AllProjectComments from "../../widgets/AllProjectComments"

const CommentsPage: FC = () => {
    return (
        <>
            <AllProjectComments/>
        </>
    )
}

export default CommentsPage