import type { FC } from "react"
import Button from "../../shared/ui/Button"
import Comment from "./ui/Comment"
import { useEffect, useState } from "react"
import classNames from "classnames"
import axios from "axios"
import { BACKEND_URL } from "../../shared/constants/contsants"
import { Link, useParams } from "react-router-dom"
import { TTicket } from "../../shared/types/comon"
import "./AllProjectComments.scss"
import { TProject } from "../UserProject/types"

const AllProjectComments: FC = () => {
    const [filter, setFilter] = useState("Все")
    const [result, setResult] = useState<Array<TTicket>>([])
    const {project_id} = useParams()

    const buttonsFilter = [
        {
            filterButton: "Все",
            text: "Show all"
        },
        {
            filterButton: "Релиз",
            text: "Done"
        },
        {
            filterButton: "В работе",
            text: "In progress"
        },
    ]
    const token: any = localStorage.token?.replace(/"/g, "")

    useEffect(() => {
        axios.get(`${BACKEND_URL}/project/get_all_projects/`, {
            headers: {
                Authorization: `JWT ${token}`
            }
        })
            .then(res => {
                res.data.map((item: TProject) => {
                    if (item?.id === Number(project_id)) {
                        setResult(item.ticket_data)
                    }
                })
            })
            .catch(err => console.log(err))
    }, [])

    const filteredArray = result.filter(({status}) => {
        if (filter === "Все") return true
        return status === filter
    })

    return (
        <section className="all-project-comments">
            <div className="all-project-comments__inner container">
                <Link to=".." relative="path" className="all-project-comments__link link link--small link--regular">
                    ← Back
                </Link>
                <h2 className="all-project-comments__title title">Your comments on the project</h2>
                <div className="all-project-comments__actions">
                    {buttonsFilter.map(({filterButton, text}) => (
                        <Button
                            className={classNames("all-project-comments__button button--simply", {
                                "is-active": filter === filterButton,
                            })}
                            key={filterButton}
                            onClick={() => setFilter(filterButton)}
                        >
                            {text}
                        </Button>
                    ))}
                </div>
                <ul className="all-project-comments__comment-list">
                    {filteredArray.map(({description, status, id,
                                         created_date}) => (
                        <li className="all-project-comments__comment-item" key={id}>
                            <Comment status={status} text={description} date={created_date}/>
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    )
}

export default AllProjectComments