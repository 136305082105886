import type { FC } from "react"
import { TMonitorProgressBox } from "./types"
import "./MonitorProgressBox.scss"

const MonitorProgressBox: FC<TMonitorProgressBox> = (props) => {
    const {
        text
    } = props

    return (
        <div className="monitor-progress-box">
            <div className="monitor-progress-box__up" />
            <div className="monitor-progress-box__down" />
            <div className="monitor-progress-box__body">
                <div className="monitor-progress-box__text">
                    <p>{text}</p>
                </div>
            </div>
        </div>
    )
}

export default MonitorProgressBox