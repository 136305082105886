import type { FC } from "react"
import RadioButtons from "../../../../shared/ui/RadioButtons"
import "./RequestRadios.scss"
import { TRequestRadios } from "./types"

const RequestRadios: FC<TRequestRadios> = (props) => {
    const {
        register
    } = props

    const radios = [
        {id: "budget-0", label: "Up to $10,000 ", value: "Up to $10.000"},
        {id: "budget-1", label: "$10,000-30,000  ", value: "$10.000 - 30.000"},
        {id: "budget-2", label: "Over $30,000  ", value: "Over $30.000"},
    ]

    return (
        <div className="request__radios">
            <div className="request__radios-title text">Future project budget</div>
            <RadioButtons className="request__radios-body" register={register("budget")} radios={radios}/>
        </div>
    )
}

export default RequestRadios