import type { FC } from "react"
import Button from "../../shared/ui/Button"
import "./Preview.scss"

const Preview: FC = () => {

  function scrollToForm() {
    const requestForm = document.getElementById("request-form");
    requestForm?.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
    });
  }

  return (
    <section className="preview">
        <div className="preview__inner container">
            <div className="preview__body">
                <h1 className="preview__title title title--very-big">Rulebreakers</h1>
                <div className="preview__hands-block visible-mobile" >
                    <div className="preview__hands-block-light"/>
                </div>
                <div className="preview__text text">Creating products, going beyond</div>
                <div className="preview__button-wrapper">
                    <Button
                        className="preview__button button--small button-contact"
                        onClick={scrollToForm}
                    >
                        Contact us
                    </Button>
                    <div className="preview__hand-wrapper">
                        <img
                            className="preview__hand"
                            src="/images/content/hand.png"
                            alt="рука"
                        />
                    </div>
                    <img
                        className="preview__hand-info"
                        src="/images/content/hand_info.png"
                        alt="рука"
                    />
                </div>
            </div>
        </div>
    </section>
  )
}

export default Preview