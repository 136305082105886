import type { FC } from "react"
import PortfolioProject from "./ui/PortfolioProject"
import "./Portfolio.scss"
import Slider from "../../shared/ui/Slider"

const Portfolio: FC = () => {
    const projects = [
        {
            title: "Development of a real estate rental service",
            img: "/images/content/project_1.jpg",
            hash: ["#B2B", "#Website Development", "#UX/UI Design"]
        },
        {
            title: "Messenger for secure communication.",
            img: "/images/content/project_2.jpg",
            hash: ["#B2B", "#Website Development", "#UX/UI Design"]
        },
        {
            title: "School of Double State education",
            img: "/images/content/project_3.jpg",
            hash: ["#B2B", "#Website Development", "#UX/UI Design"]
        },
        {
            title: "Service for creating corporate presentations",
            img: "/images/content/project_4.jpg",
            hash: ["#Service Development", "#FinTech"]
        },
    ]

    return (
        <section className="portfolio" id="portfolio">
            <div className="portfolio__inner">
                <h2 className="portfolio__title title">Our work_</h2>
                <Slider>
                    <div className="portfolio__body">
                        <ul className="portfolio__project-list">
                            {projects.map(({img, title, hash}) => (
                                <li className="portfolio__project-item" key={title}>
                                    <PortfolioProject hash={hash} img={img} title={title}/>
                                </li>
                            ))}
                        </ul>
                    </div>
                </Slider>
            </div>
        </section>
    )
}

export default Portfolio