import type { FC } from "react"
import "./Contact.scss"

const Contact: FC = () => {
  return (
    <section className="contact" id="contact">
        <div className="contact__inner container">
            <h2 className="contact__title title">Contact</h2>
            <div className="contact__row">
                <div className="contact__name text">Our e-mail</div>
                <div className="contact__value title">nikita.s.mozhaev@rulebreakers.tech</div>
            </div>
            <div className="contact__row">
                <div className="contact__name text">We in Telegram</div>
                <div className="contact__value title">
                    <svg className="contact__value-image" width="55.59px" height="51.14px" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.8629 14.1369L17.1288 7.87089M23.5386 4.04686L18.2495 21.2366C17.7755 22.7771 17.5383 23.5477 17.1295 23.8032C16.7749 24.0247 16.3359 24.0618 15.9497 23.9015C15.5043 23.7167 15.1429 22.9951 14.4219 21.553L11.0719 14.8529C10.9574 14.6241 10.9002 14.5101 10.8237 14.411C10.7559 14.323 10.6776 14.2437 10.5897 14.1759C10.4928 14.1012 10.3807 14.0452 10.1621 13.9359L3.44661 10.5781C2.00455 9.85704 1.28346 9.49619 1.09868 9.05087C0.938429 8.66466 0.974974 8.22521 1.19653 7.87061C1.45201 7.46172 2.22256 7.22418 3.76354 6.75003L20.9531 1.46088C22.1645 1.08812 22.7705 0.901886 23.1797 1.05211C23.5361 1.18296 23.8171 1.46368 23.9479 1.82011C24.0981 2.22912 23.9117 2.8348 23.5393 4.04507L23.5386 4.04686Z" stroke="#F0EFF2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    @rules_support
                </div>
            </div>
        </div>
    </section>
  )
}

export default Contact