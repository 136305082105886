import type { FC } from "react"
import MonitorProgressBox from "./ui/MonitorProgressBox"
import { Swiper, SwiperSlide } from "swiper/react"
import { EffectCoverflow, Pagination } from "swiper/modules"
import "./MonitorProgress.scss"
import 'swiper/css';
import 'swiper/css/pagination'
import 'swiper/css/effect-coverflow';

const MonitorProgress: FC = () => {
    const boxTexts = [
        {
            id: 0,
            text: "See all projects that are taken into development by our studio"
        },
        {
            id: 1,
            text: "Track overall progress for each project"
        },
        {
            id: 2,
            text: "Get to know the stages of creating your projects"
        },
        {
            id: 3,
            text: "And also with the tasks within each stage"
        },
        {
            id: 4,
            text: "You can find links to all relevant artifacts on the project page"
        },
        {
            id: 5,
            text: "In addition, you can leave comments and make changes to the current work"
        },
    ]

    return (
        <section className="monitor-progress">
            <div className="monitor-progress__inner container">
                <div className="monitor-progress__header">
                    <h2 className="monitor-progress__title title">Monitor the progress of your project online_</h2>
                    <div className="monitor-progress__text text text--gray">
                        <p>
                            Immediately after agreeing on the details, you will get access to your personal account,
                            where you can:
                        </p>
                    </div>
                </div>
                <Swiper
                    className="monitor-progress__swiper visible-mobile"
                    // @ts-ignore
                    modules={[Pagination, EffectCoverflow]}
                    slidesPerView={1}
                    spaceBetween={12}
                    effect="coverflow"
                    pagination={{
                        clickable: true,
                        el: '.monitor-progress__pagination',
                        bulletElement: 'button',
                        bulletClass: 'monitor-progress__pagination-bullet',
                        bulletActiveClass: "is-active",
                    }}
                >
                    {boxTexts.map(({id, text}) => (
                        <SwiperSlide key={id}>
                            <MonitorProgressBox text={text}/>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div className="monitor-progress__pagination visible-mobile" />
                <div className="monitor-progress__body hidden-mobile">
                    {boxTexts.map(({id, text}) => (
                        <MonitorProgressBox key={id} text={text}/>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default MonitorProgress