import type { FC } from "react"
import { useEffect, useState } from "react"
import "./ProfilePage.scss"
import UserProject from "../../widgets/UserProject"
import axios from "axios"
import { BACKEND_URL } from "../../shared/constants/contsants"
import { useNavigate } from "react-router-dom"

const ProfilePage: FC = () => {

  const [projects, setProjects] = useState<any>()
  const [name, setName] = useState<string>("")
  const [lastName, setLastName] = useState<string>("");
  const token:any = localStorage.token?.replace(/"/g, '')
  const navigate = useNavigate()

  useEffect(() => {
    axios.get(`${BACKEND_URL}/project/get_all_projects/`, {
      headers: {
        Authorization: `JWT ${token}`
      }
    })
    .then(res => {
      setProjects(res.data)
      setName(res.data[0]?.user_data?.first_name)
      setLastName(res.data[0]?.user_data?.last_name)
    })
    .catch(err => navigate("/404", {replace: true}));
  }, [])

  return (
    <section className="profile">
      <div className="profile__inner container">
          <h2 className="title">{name} {lastName}</h2>
          <div className="profile__projects">
            {projects?.map((project:any) => {
              return (
                <UserProject
                  id={project.id} 
                  name={project.name}
                  description={project.description}
                  stage_data={project.stage_data}
                  ticket_data={project.ticket_data}
                  key={project.id}/>
              )
            })}
          </div>
      </div>
    </section>
  )
}

export default ProfilePage