import type { FC } from "react"
import Button from "../../shared/ui/Button"
import { useNavigate } from "react-router-dom"
import "./Error404.scss"

const Error404: FC = () => {
    const navigate = useNavigate()

    return (
        <section className="error404">
            <div className="error404__inner container">
                <img
                    className="error404__image"
                    src="/images/content/error_image.png"
                    alt="error"
                    width="1092" height="476" loading="lazy"
                />
                <h2 className="error404__title title title--green">Error</h2>
                <div className="error404__text text">Unfortunately, the page you requested was not found.</div>
                <Button
                    className="error404__button button--big"
                    onClick={() => navigate("/")}
                >
                    Go back to the main page
                </Button>
            </div>
        </section>
    )
}

export default Error404