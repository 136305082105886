import type { FC } from "react"
import classNames from "classnames"
import { TCheckBoxesGroup } from "./types"
import CheckBox from "./ui/CheckBox"
import "./CheckBoxesGroup.scss"

const CheckBoxesGroup: FC<TCheckBoxesGroup> = (props) => {
    const {
        className,
        checkboxes,
        register
    } = props

    return (
        <div className={classNames("checkbox-group", className)}>
            <div className="checkbox-group__body">
                {checkboxes.map((element) => {
                    return (
                        <CheckBox
                            register={register?.(element.name)}
                            name={element.name}
                            label={element.label}
                            key={element.name}
                            isCheckedDefault={element.isCheckedDefault}
                        />
                    )
                })
                }
            </div>
        </div>
    )
}

export default CheckBoxesGroup