import type { FC } from "react"
import "./ProgressStrip.scss"
import { TProgresStrip } from "./types";

const ProgressStrip: FC<TProgresStrip> = (props) => {

  const {progress} = props;

  return (
    <div className="profile__project-progress-strip--fill" style={{width: `${progress}%`}}>

    </div>
  )
}

export default ProgressStrip