import type { FC } from "react"
import classNames from "classnames"
import { TRadioButtons } from "./types"
import { useState } from "react"
import "./RadioButtons.scss"

const RadioButtons: FC<TRadioButtons> = (props) => {
    const {
        className,
        radios,
        register,
    } = props

    const defaultCheckedRadio = radios.find(({isChecked}) => isChecked) || radios[0]
    const [checkedRadioId, setCheckedRadioId] = useState(defaultCheckedRadio.id)

    return (
        <div className="radio">
            <div className={classNames("radio__body", className)}>
                {radios.map((element) => {
                    return (
                        <div className="radio__wrapper" key={element.id}>
                            <input
                                className="radio__control"
                                { ...register }
                                id={element.id}
                                value={element.value}
                                type="radio"
                                checked={element.id === checkedRadioId}
                                onChange={() => setCheckedRadioId(element.id)}
                            />
                            <label className="radio__control-label" htmlFor={element.id}>{element.label}</label>
                        </div>)
                })}
            </div>
        </div>
    )
}

export default RadioButtons