import type { FC, HTMLAttributes } from "react"
import classNames from "classnames"
import "./Loading.scss"
import { TLoading } from "./types"

const Loading: FC<TLoading> = (props) => {
    const {
        className,
        isFullScreen
    } = props

    return (
        <div className={classNames(className, "loading", {
            "is-full": isFullScreen
        })}>
            <div className="loading__body">
                <svg
                    className="loading__spinner"
                    width="90px"
                    height="90px"
                    viewBox="0 0 66 66"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle
                        className="loading__spinner-path"
                        fill="none"
                        strokeWidth="6"
                        strokeLinecap="round"
                        cx="33"
                        cy="33"
                        r="30">
                    </circle>
                </svg>
            </div>
        </div>
    )
}

export default Loading