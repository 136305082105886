import type { FC } from "react"
import type { TInput } from "./types"
import classNames from "classnames"
import "./Input.scss"

const Input: FC<TInput> = (props) => {
    const {
        className,
        register,
        hasError,
        error,
        ...rest
    } = props

    return (
        <div className="input">
            <input
                className={classNames(className, "input__control")}
                {...register}
                {...rest}
            />
            {hasError && error && <div className="input__error error">{error}</div>}
        </div>

    )
}

export default Input