import type { FC } from "react"
import ServicesRow from "./ui/ServicesRow"
import "./Services.scss"

const Services: FC = () => {
    const rowArray = [
        {
            id: 0,
            title: "Custom software development",
            text: "Full software development cycle, from idea to implementation and support",
            image: "/images/content/services_men_round.png",
            isImageBig: false,
            isImageArrow: false,
        },
        {
            id: 1,
            title: "Virtual and augmented reality",
            text: "Creating premium experiences in VR and AR for entertainment, education, and medicine",
            image: "/images/content/services_men_triangle.png",
            isImageBig: false,
            isImageArrow: false,
        },
        {
            id: 2,
            title: "Blockchain and cryptotechnologies",
            text: "Development and implementation of AI solutions for automating and improving business processes",
            image: "/images/content/services_funnel.png",
            isImageBig: true,
            isImageArrow: false,
        },
        {
            id: 3,
            title: "Artificial intelligence and machine learning",
            text: "Full software development cycle, from idea to implementation and support",
            image: "/images/content/services_computers.png",
            isImageBig: false,
            isImageArrow: false,
        },
        {
            id: 4,
            title: "Consulting and Strategy",
            text: "Assistance in developing a long-term IT strategy and support in technological development",
            image: "/images/content/services_arrow.png",
            isImageBig: false,
            isImageArrow: true,
        },
    ]

    return (
        <section className="services" id="services">
            <div className="services__inner container">
                <h2 className="services__title title">Services_</h2>
                <div className="services__body">
                    {rowArray.map(({id, title, text, image, isImageBig, isImageArrow}) => (
                        <ServicesRow key={id} title={title} text={text} image={image} isImageBig={isImageBig} isImageArrow={isImageArrow}/>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default Services