import type { FC } from "react"
import Stage from "./ui/Stage"
import ProjectStagesTask from "./ui/ProjectStagesTask"
import { useState } from "react"
import classNames from "classnames"
import { TStages } from "./types"
import Slider from "../../shared/ui/Slider"
import "./ProjectStages.scss"

const ProjectStages: FC<TStages> = (props) => {

    const {projectStages} = props
    const [isActiveStage, setActiveStage] = useState<any>()
    const tasks = isActiveStage?.task_data

    return (
        <section className="project-stages">
            <div className="project-stages__stages">
                <h2 className="project-stages__title title title--very-small container">Development stages</h2>
                <div className="project-stages__container container">
                    <Slider>
                        <ul className="project-stages__stage-list">
                            {projectStages.map((element) => (
                                <li className="project-stages__stage-item" key={element.id}>
                                    <Stage
                                        className={classNames({
                                            "is-active": element.id === isActiveStage?.id,
                                        })}
                                        name={element.name}
                                        status={element.status}
                                        onClick={() => setActiveStage(element)}
                                    />
                                </li>
                            ))}
                        </ul>
                    </Slider>

                </div>
            </div>
            <ProjectStagesTask tasks={tasks}/>
        </section>
    )
}

export default ProjectStages