import type { TGetDateFormatted } from "./types"

const getDateFormatted: TGetDateFormatted = (time) => {
    const date = new Date(time)
    if (!date) return ""
    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()

    const formattedMonth = month < 10 ? `0${month}` : month

    return [day, formattedMonth, year].join(".")
}

export default getDateFormatted